<template>
  <Marquee class="z-10" :color="'bg-gray-100'">
    <div
      class="flex items-center gap-x-2 lg:gap-x-3 text-gray-700"
      v-for="info in arregloMarquee"
    >
      <component
        :is="info.icono"
        class="h-4 w-4 sm:h-6 sm:w-6 stroke-1 shrink-0"
      />
      <span class="text-xs sm:text-base">
        {{ info.textoUno }}
        {{ info.textoDos }}
      </span>
    </div>
  </Marquee>
</template>
<script setup lang="ts">
  import Marquee from "@components/ui/Marquee.vue";
  import BananasIcon from "@components/iconos/BananasIcon.vue";
  import { HeartIcon, TruckIcon } from "@heroicons/vue/24/outline";

  let arregloMarquee = [
    {
      icono: BananasIcon,
      textoUno: "Expertos en ",
      textoDos: "frutas y verduras",
    },
    {
      icono: HeartIcon,
      textoUno: "Más de 600 ",
      textoDos: "productos saludables",
    },
    {
      icono: TruckIcon,
      textoUno: "Pide hoy, recibe hoy ",
      textoDos: "o cuando quieras",
    },
  ];
  for (let i = 0; i < 5; i++) {
    arregloMarquee.push(...arregloMarquee);
  }
</script>
