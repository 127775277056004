<script setup lang="ts">
  import { TruckIcon } from "@heroicons/vue/24/outline";
  import {
    abrirModalDespachoLogin,
    setearComponenteActivoModalDespachoLogin,
    setearTabRegistroLoginModalDespacho,
  } from "@stores/despacho";
  import { direccionActiva as storeDireccion } from "@stores/despacho";
  import { useStore } from "@nanostores/vue";
  import { computed } from "@vue/runtime-core";
  import { user as storeUser } from "@stores/user";
  import type { User } from "@lib/interfaces";

  interface Props {
    user: User | null;
  }

  const props = defineProps<Props>();

  const $direccion = useStore(storeDireccion);
  const $user = useStore(storeUser);

  const userAutenticado = computed(() => {
    return $user.value != null;
  });

  const visible = computed(() => {
    if (props.user) {
      return false;
    } else if (userAutenticado.value) {
      return false;
    } else {
      return true;
    }
  });

  const onClick = () => {
    if (!userAutenticado.value && $direccion.value) {
      setearTabRegistroLoginModalDespacho("login");
      setearComponenteActivoModalDespachoLogin("RegistroLogin");
      abrirModalDespachoLogin();
      return;
    }
    setearComponenteActivoModalDespachoLogin("SeleccionarDespacho");
    abrirModalDespachoLogin();
  };
</script>

<template>
  <div
    v-if="visible"
    class="flex mx-auto px-2.5 py-1.5 md:px-5 md:py-2.5 lg:py-5 justify-center text-white bg-rose-500 rounded-lg items-center w-full max-w-96 lg:max-w-5xl md:max-w-max md:rounded-2xl lg:rounded-3xl gap-x-2 md:gap-x-4 lg:gap-x-5"
  >
    <TruckIcon class="h-5 w-5 md:h-9 md:w-9" />
    <div class="text-[11px] md:text-lg lg:text-xl font-bold flex items-center">
      ENVÍO GRATIS DESDE $29.990
      <span class="hidden md:contents"> REGISTRÁNDOTE</span>
      <span class="hidden lg:contents"> EN FREST.CL</span>
      🔥🔥🔥
    </div>
    <button
      @click="onClick"
      class="flex items-center h-5 md:h-8 text-black text-[8px] md:text-xs font-semibold py-1 px-2 bg-yellow-600 rounded-sm md:px-5 md:py-2.5 lg:py-2 lg:px-16 shadow-sm"
    >
      REGÍSTRATE
    </button>
  </div>
</template>
